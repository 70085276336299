import { ENTRY_CREATED, ENTRY_DELETED, ENTRY_GET_ALL } from '../types';

const initialState = {
};

const getAllEntries = (state, payload, username) => {
  if (!state.hasOwnProperty(username)) {
    state[username] = {}
    state[username]['calentries'] = payload.calentries;
    state[username]['count'] = payload.calentriesCount;
    state[username]['loading'] = false
  }
  return {
    ...state
  }
};
const addCalEntry = (state, payload, username) => {
  if (state.hasOwnProperty(username)) {
    state[username]['calentries'].push(payload.calentries);
    state[username]['count'] = payload.calentriesCount;

  } else {
    state[username] = {}
    state[username]['calentries'] = [payload.calentries]
    state[username]['count'] = payload.calentriesCount;
  }
  state[username]['loading'] = false;
  return {
    ...state
  };
};

const deleteCalEntry = (state, username, calendar_entry_id) => {
  if (!state.hasOwnProperty(username)) return

  let items = state[username]['calentries'].filter(item => {
    return item.id !== calendar_entry_id
  })
  state[username]['calentries'] = items ? items : [];
  state[username]['count'] = (state[username]['count'] > 0 ? state[username]['count'] - 1 : 0)
  state[username]['loading'] = false;
  return {
    ...state
  };
};

const calendar = (state = initialState, action) => {
  const { type, payload, username, calendar_entry_id } = action;

  switch (type) {
    case ENTRY_GET_ALL:
      return getAllEntries(state, payload, username);
    case ENTRY_CREATED:
      return addCalEntry(state, payload, username);
    case ENTRY_DELETED:
      return deleteCalEntry(state, username, calendar_entry_id);
    default:
      return state;
  }
};
export default calendar;
