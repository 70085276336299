import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Loading from '../components/Loading';
import { WithLayoutRoute, ProtectedRoute } from '../routers';


import { MainLayout } from '../layouts';
import { OracleLayout } from '../layouts';
import { StructureLayout } from '../layouts';

// Register - Login
const RegisterPage = lazy(() => import('../pages/RegisterPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const ResetPage = lazy(() => import('../pages/ResetPage'));
const ResetConfirmPage = lazy(() => import('../pages/ResetConfirmPage'));

// Public
const HomePage = lazy(() => import('../pages/HomePage'));
const BrainstormPage = lazy(() => import('../pages/BrainstormPage'));
const InspirationPage = lazy(() => import('../pages/InspirationPage'));
const PlacePage = lazy(() => import('../pages/PlacePage'));
const EventPage = lazy(() => import('../pages/EventPage'));
const TermsPage = lazy(() => import('../pages/TermsPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'));
const ExplorePage = lazy(() => import('../pages/ExplorePage'));

// User Context
const ProfilePage = lazy(() => import('../pages/ProfilePage'));
const ProfileFollowingPage = lazy(() => import('../pages/ProfileFollowingPage'));
const ProfileFollowedByPage = lazy(() => import('../pages/ProfileFollowedByPage'));
const MyAirportsPage = lazy(() => import('../pages/MyAirportsPage'));
const MySchedulePage = lazy(() => import('../pages/MySchedulePage'));

const Routes = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/register" component={RegisterPage} />
                    <Route exact path="/reset" component={ResetPage} />
                    <Route path="/reset-confirm/:user_key/:token/" render={(props) => <ResetConfirmPage {...props} />} />
                    <Route path="/api/^activate/*" component={LoginPage} />

                    {/*<WithLayoutRoute
              exact
              path="/"
              layout={MainLayout}
              layoutProps={{ withFooter: true }}
              component={HomePage}
          />*/}
                    <WithLayoutRoute
                        exact
                        path="/"
                        layout={StructureLayout}
                        layoutProps={{ isHome: true, withFooter: false }}
                        component={HomePage}
                    />
                    <ProtectedRoute
                        exact
                        path="/brainstorm1"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={BrainstormPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/brainstorm"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={BrainstormPage}
                    />
                    <WithLayoutRoute
                        path="/explore"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={ExplorePage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/entities/:slug"
                        layout={StructureLayout}
                        layoutProps={{withHeader: false, withFooter: false }}
                        component={InspirationPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/entities/:slug/slideshow"
                        layout={StructureLayout}
                        layoutProps={{withHeader: false, withFooter: false }}
                        component={InspirationPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/place/:slug"
                        layout={StructureLayout}
                        layoutProps={{withHeader: false, withFooter: false }}
                        component={PlacePage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/event/:slug"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={EventPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/terms"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={TermsPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/privacy"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={PrivacyPage}
                    />

                    <WithLayoutRoute
                        exact
                        path="/profile/@:username"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={ProfilePage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/profile/@:username/:filter"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: true }}
                        component={ExplorePage}
                    />

                    <ProtectedRoute
                        exact
                        path="/profile/myairports/@:username"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={MyAirportsPage}
                    />
                    <ProtectedRoute
                        exact
                        path="/profile/myschedule/@:username"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={MySchedulePage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/profile/followedby/@:username"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={ProfileFollowingPage}
                    />
                    <WithLayoutRoute
                        exact
                        path="/profile/following/@:username"
                        layout={OracleLayout}
                        layoutProps={{ withFooter: false }}
                        component={ProfileFollowedByPage}
                    />

                    <Route path="*" component={() => '404 NOT FOUND'} />
                </Switch>
            </Router>
        </Suspense>
    );
};

export default Routes;
