import { setAlert } from './alert';
import { NOTIFICATIONS_GET_UNREAD } from '../types';

import { setAuthHeadersAsJson, WAW_NOTIFICATIONS_ROOT } from '../../utils';
import { removeLoading, setLoading } from "./loading";

const limit = (count, p) => ``;


export const getUnreadNotifications = () => async dispatch => {
    //dispatch(setLoading(5000))
    try {
        const url = `/unread/?format=json`;
        const response = await fetch(`${WAW_NOTIFICATIONS_ROOT}${url}`, {
            method: 'GET',

        });
        var body = await response.json();

        dispatch({ type: NOTIFICATIONS_GET_UNREAD, payload: body });
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    //dispatch(removeLoading(5000))
};