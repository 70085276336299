import { setAlert } from "./alert";
import { COMMENT_ADDED, COMMENT_DELETED, COMMENT_GET_ALL } from "../types/comments";
import { getErrorMsg, setAuthHeaders, setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";


export const getEntityComments = (entityId) => async dispatch => {
    try {
        const url = `/comments/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson(),
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: COMMENT_GET_ALL, payload: responseData, entityId: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

export const addComment = (entityId, comment) => async dispatch => {
    try {
        const url = `/comments/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(comment)
        });
        const responseData = await response.json();
        if (response.status === 201) {
            dispatch({ type: COMMENT_ADDED, payload: responseData, entityId: entityId });
        } else {
            const msg = getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
            console.log(responseData)
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const deleteComment = (entityId, commentId) => async dispatch => {
    try {
        const url = `/comments/entities/${entityId}/${commentId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders(),
        });
        if (response.status === 204) {
            dispatch({ type: COMMENT_DELETED, entityId: entityId, commentId: commentId });
        } else {
            const responseData = await response.json();
            const msg = getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};