import { COMMENT_ADDED, COMMENT_DELETED, COMMENT_GET_ALL } from "../types";

const initialState = {
};

const getComments = (state, payload, entityId) => {
    if (!state.hasOwnProperty(entityId)) {
        state[entityId] = {}
        state[entityId]['comments'] = payload.comments;
        state[entityId]['count'] = payload.commentCount;
        state[entityId]['loading'] = false
    }

    return {
        ...state
    }
};

const addComment = (state, payload, entityId) => {
    if (state.hasOwnProperty(entityId)) {
        state[entityId]['comments'].push(payload.comments);
        //state[entityId]['count'] += 1;
        state[entityId]['count'] = payload.commentCount;

    } else {
        state[entityId] = {}
        state[entityId]['comments'] = [payload.comments]
        state[entityId]['count'] = payload.commentCount;
    }
    state[entityId]['loading'] = false;
    return {
        ...state
    };
};

const deleteComment = (state, commentId, entityId) => {
    if (!state.hasOwnProperty(entityId)) return

    let items = state[entityId]['comments'].filter(item => {
        return item.id !== commentId
    })
    state[entityId]['comments'] = items ? items : [];
    state[entityId]['count'] = (state[entityId]['count'] > 0 ? state[entityId]['count'] - 1 : 0)
    state[entityId]['loading'] = false;
    return {
        ...state
    };
};

const comments = (state = initialState, action) => {
    const { type, payload, commentId, entityId } = action;
    switch (type) {
        case COMMENT_GET_ALL:
            return getComments(state, payload, entityId);
        case COMMENT_ADDED:
            return addComment(state, payload, entityId);
        case COMMENT_DELETED:
            return deleteComment(state, commentId, entityId);
        default:
            return state;
    }
};
export default comments;