// @ts-nocheck
import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from './store';
import theme from './theme';
import { Alert, Loading } from './components';
import Routes from './routes/Routes';
import './assets/scss/index.scss';
import './assets/css/app.css';
import { CssBaseline } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Loader } from '@googlemaps/js-api-loader'
import { GoogleOAuthProvider } from '@react-oauth/google';

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    id: "__googleMapsScriptId",
    libraries: ["places"]
});
loader.load()

class App extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Provider store={store}>
                    <ParallaxProvider>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Alert />
                            <Loading />
                            <Routes />
                        </ThemeProvider>

                    </ParallaxProvider>
                </Provider>
            </GoogleOAuthProvider>
        );
    }
}

export default App;
