import {
    AIRPORT_ENTITY_ADDED,
    AIRPORT_ENTITY_DELETED,
    AIRPORT_ENTITY_GET_ALL,
    AIRPORT_GET_TO,
    AIRPORT_GET_FROM,
    AIRPORT_PROFILE_ADDED,
    AIRPORT_PROFILE_DELETED,
    AIRPORT_PROFILE_GET_ALL
} from "../types";
import { setAlert } from "./alert";
import { removeLoading, setLoading } from "./loading";
import { setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";

export const addProfileAirport = (username, airport) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/airports`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(airport)
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_PROFILE_GET_ALL, payload: responseData, username: username });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const deleteProfileAirport = (username, airportCode) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/airports/${airportCode}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_PROFILE_DELETED, payload: responseData, username: username, airportCode: airportCode });
        } else {
            dispatch(setAlert(response.statusText, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const getProfileAirports = (username) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_PROFILE_GET_ALL, payload: responseData, username: username });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const getFromAirports = () => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/airports/direction/from`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_GET_FROM, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const getToAirports = (query) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/airports//direction/to`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_GET_TO, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const addEntityAirport = (entityId, airport, direction) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/airports/entities/${entityId}/to`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(airport)
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_ENTITY_GET_ALL, payload: responseData, entityId: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const deleteEntityAirport = (entityId, airport) => async dispatch => {
    if (!airport) return;
    dispatch(setLoading(5000))
    try {
        const url = `/airports/entities/${entityId}/direction/${airport.direction}/${airport.code}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_ENTITY_DELETED, payload: responseData, entityId: entityId, airportCode: airport.code });
        } else {
            dispatch(setAlert(response.statusText, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const getEntityAirports = (entityId) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/airports/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: AIRPORT_ENTITY_GET_ALL, payload: responseData, entityId: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};
