const css = (theme) => ({
  appbar: {
    background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(244,244,244,0.2) 100%) !important',
    color: '#fff !important',
    borderBottom: '0px solid rgba(0,0,0,.05)',
    boxShadow: 'none !important',
    [theme.breakpoints.down("sm")]: {
      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(244,244,244,0) 100%) !important'
    }
  },
  logoBox: {
    alignItems: 'left',
    display: 'flex',
    gap: '20px',
  },
  logoImg: {
    height: '48px',
    width: '48px',
    display:'inline-flex'
  },
  logoText: {
    color:'#fff',
    fontWeight:700,
    display:'inline-flex',
    textTransform:'uppercase',
    fontFamily: '"ColfaxAI",Helvetica,sans-serif',
    fontSize: '16px'
  },
  logoImgSm: {
    width: '100%',
    maxHeight: '30px',
  },
  logo: {
    display: { xs: 'none', md: 'flex' },
  },
  snsIcon: {
    color:'#263238',
    fontSize:'22px',
    "&:hover": {
      color: '#485D7C',
    },
  }
});
export default css;
