export const WAW_API_ROOT = 'v1/api';
export const WAW_AUTH_ROOT = 'v1/auth/dj-rest-auth';
export const WAW_NOTIFICATIONS_ROOT = 'v1/notifications';


export const getQueryLoading = (state, key) => {
    return state['queries'] && state['queries'].hasOwnProperty(key) ? state['queries'][key]['loading'] : false;
}

export const getQueryCount = (state, key) => {
    return state['queries'] && state['queries'].hasOwnProperty(key) ? state['queries'][key]['count'] : null;
}

export const getQueryEntities = (state, key) => {
    var result = null;
    if (state['queries'] && state['queries'].hasOwnProperty(key)) {
        result = [];
        for (var id in state['queries'][key]['entities']) {
            result.push(state['entities'][id]);
        }

        return (
            result.sort((x, y) => {
                return new Date(x.created_at) < new Date(y.created_at) ? 1 : -1
            })
        );
    }
    return result;
}

export const getEntityFromStore = (state, id, slug) => {

    if (id !== null) {
        if (state['entities'] && state['entities'].hasOwnProperty(id)) {
            return state['entities'][id];
        }
    }
    if (slug != null && state['entities']) {
        for (var id in state['entities']) {
            var entity = state['entities'][id];
            if (entity.slug === slug) return entity;
        }
    }

    return null;
}


