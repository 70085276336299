import { setAlert } from './alert';
import {
    ENTITIES_GET_BY_QUERY,
    ENTITIES_SET_LOADING,
    ENTITY_CREATED,
    ENTITY_UPDATED,
    ENTITY_RETRIEVED,
    ENTITY_DELETED,
    MEDIA_RECEIVED_LINKED_ENTITIES,
} from '../types';
import { getErrorMsg, setAuthHeaders, setAuthHeadersAsJson, WAW_API_ROOT } from '../../utils';
import { removeLoading, setLoading } from "./loading";
import { processError } from "./admin";

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
//const limit = (count, p) => ``;
//const omitSlug = entity => Object.assign({}, entity, {slug: undefined});

const USER_ERROR_MSG = 'Unable to process request. We are working to fix the issue. Please try again later.';

export const createEntity = (entity, parentSlug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = parentSlug ? `/entities/${parentSlug}/` : `/entities/create`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ 'entity': entity })
        });
        const responseData = await response.json();
        if (response.ok) {
            var entity = responseData.entity;
            dispatch({ type: ENTITY_CREATED, payload: entity });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(createEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(createEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const addLocation = (entityId, location) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/update-partial/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ location: location })
        });
        const responseData = await response.json();
        if (response.ok) {
            var entity = responseData.entity;
            dispatch({ type: ENTITY_UPDATED, payload: entity });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(addLocation.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(addLocation.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const deleteLocation = (entityId, locationId) => async dispatch => {
    dispatch(setLoading(5000))

    try {
        const url = `/entities/update-partial/${entityId}/location/${locationId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            var entity = responseData.entity;
            dispatch({ type: ENTITY_UPDATED, payload: entity });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(deleteLocation.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(deleteLocation.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};


export const getEntity = (slug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_RETRIEVED, payload: responseData });
            //dispatch({type: PROFILE_SET, payload: responseData.author});
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(getEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(getEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};


export const getQuery = (type, filter, username, lat, lng, radius, tag, parentId, childId) => {
    var query = '';
    query += `type=${type ? type : 'all'}`;

    if (filter && filter.length > 0) {
        if (['favorited', 'tracked', 'upvoted', 'created', 'featured', 'linked', 'main', 'featured', 'nearby', 'tagged'].indexOf(filter) > -1) {
            query += '&filter=' + filter;
        }
        if (username != null) {
            query += '&username=' + username;
        }
        if (parentId != null) {
            query += '&parentId=' + parentId;
        }
        if (childId != null) {
            query += '&childId=' + childId;
        }
        if (filter === 'nearby' && lat != null && lng != null && radius != null) {
            query += '&lat=' + lat + '&lng=' + lng + '&radius=' + radius;
        }
        if (tag != null) {
            query += `&tag=${encodeURIComponent(tag)}`;
        }
    }
    return query;
}

export const getEntitiesByQuery = (key, query, includeMedia = false, limitNumber = 100, page = 0) => async dispatch => {
    //dispatch(setLoading(5000))
    dispatch({ type: ENTITIES_SET_LOADING, payload: true, key: key });
    try {
        const url = `/entities/?${query}&media=${includeMedia}&${limit(limitNumber, page)}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITIES_GET_BY_QUERY, payload: responseData, key: key });
            dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(getEntitiesByQuery.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(getEntitiesByQuery.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch({ type: ENTITIES_SET_LOADING, payload: false, key: key });
    //dispatch(removeLoading(5000))
};

export const getNearByEntities = (key, lat, lng, radius, includeMedia = false, limitNumber = 10, page = 0) => async dispatch => {
    dispatch(setLoading(5000))
    dispatch({ type: ENTITIES_SET_LOADING, payload: true });
    try {
        const url = `/entities/search?lat=${lat}&lng=${lng}&radius=${radius}&media=${includeMedia}&${limit(limitNumber, page)}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITIES_GET_BY_QUERY, payload: responseData, key: key });
            dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(getNearByEntities.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(getNearByEntities.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch({ type: ENTITIES_SET_LOADING, payload: false });
    dispatch(removeLoading(5000))
};

export const getFeaturedEntities = (type, includeParent = false, includeMedia = false, limitNumber = 10, page = 0) => async dispatch => {
    const query = `type=${type}&parent=${includeParent ? 1 : 0}&featured=1`;
    dispatch(getEntitiesByQuery(query, includeMedia, limitNumber, page));
    return query;
};

export const getFeedEntities = (key, type, limitNumber = 10, page = 0) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/feed/?type=${type}&${limit(limitNumber, page)}`
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITIES_GET_BY_QUERY, payload: responseData, key: key });
            dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(getFeedEntities.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(getFeedEntities.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const favoriteEntity = (type, slug, isFavorite) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/favorite`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: (isFavorite ? 'POST' : 'DELETE'),
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(favoriteEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(favoriteEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const watchEntity = (type, slug, flag) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/watch`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: (flag ? 'POST' : 'DELETE'),
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(watchEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(watchEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};



export const featureEntity = (type, slug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/feature/`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
            dispatch(setAlert(slug + (responseData.entity.featured ? ' is featured' : ' removed from featured'),
                'success', 5000));
        } else {
            const msg = getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(featureEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(featureEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }

    dispatch(removeLoading(5000))
};


export const saveEntityAsMain = (type, slug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/main/`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
            dispatch(setAlert(slug + (responseData.entity.main ? ' saved as main' : ' removed as main'), 'success', 5000));
        } else {
            const msg = getErrorMsg(responseData)
            dispatch(processError(saveEntityAsMain.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(saveEntityAsMain.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const saveEntity = (parentId, id, flag, media) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        let url = `/entities/${parentId}/save/${id}`;

        console.log('saveEntity', parentId, id, media)


        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: flag ? 'PUT' : 'DELETE',
            headers: setAuthHeaders(),
            body: media ? JSON.stringify({ 'media': media }) : null
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData, id: id });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            throw new Error(msg)
        }
    } catch (error) {
        console.log(error)
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};


export const publishEntity = (type, slug, flag) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/publish/`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: flag ? 'POST' : 'DELETE',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
            dispatch(setAlert(type + ' is published', 'success', 5000));
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(publishEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(publishEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const upvoteEntity = (type, slug, isUpvoted) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}/upvote`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: isUpvoted ? 'POST' : 'DELETE',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(upvoteEntity.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(upvoteEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const deleteEntity = (id, slug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${slug}`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders()
        });
        if (response.status === 204) {
            dispatch({ type: ENTITY_DELETED, id: id, slug: slug });
        }

    } catch (error) {
        dispatch(processError(deleteEntity.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const saveEntityName = (id, type, name) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/update-partial/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ name, id: id })
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(saveEntityName.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        }
    } catch (error) {
        dispatch(processError(saveEntityName.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};


export const saveEntityDescription = (id, type, description) => async dispatch => {
    dispatch(setLoading(5000))
    try {

        const url = `/entities/update-partial/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ description, id: id })
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ENTITY_UPDATED, payload: responseData });
        }
        if (responseData.error) {
            var msg = getErrorMsg(responseData.error)
            dispatch(processError(saveEntityDescription.name, msg, 'error'))
            dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));

        }
    } catch (error) {
        dispatch(processError(saveEntityDescription.name, error, 'error'))
        dispatch(setAlert(USER_ERROR_MSG, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};
