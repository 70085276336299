import {
    MEDIA_ADDED,
    MEDIA_DELETED,
    MEDIA_GET_DONE,
    MEDIA_GET_FLICKR,
    MEDIA_GET_PIXABAY,
    MEDIA_GET_START,
    MEDIA_GET_WIKI,
    MEDIA_GET_GOOGLE,
    MEDIA_RECEIVED,
    MEDIA_RECEIVED_LINKED_ENTITIES
} from "../types";

const initialState = {
    media: null
};

const loadingMedia = (state, id, loading) => {
    let media = { ...state };
    if (!media[id]) { media[id] = {} }
    media[id].loading = loading;
    return media;
};

const getMedia = (state, payload, id) => {
    let media = { ...state };
    if (!media[id]) { media[id] = {} }
    media[id].media = payload?.medias;
    media[id].mediaCount = payload?.mediaCount;
    media[id].loading = false;
    return {
        ...media
    }
};


const getMediaFromLinkedEnities = (state, payload) => {
    let media = { ...state };
    if (payload.entities) {
        for (var idx in payload.entities) {
            var entity = payload.entities[idx];
            var id = entity.id;
            if (!media[id]) media[id] = {}
            media[id].media = entity.media;
            media[id].mediaCount = entity.media ? entity.media.length : 0;
            media[id].loading = false;
        };
    }
    return {
        ...media
    }
}

const getFlickrMedia = (state, payload, id) => {
    let photos = payload && payload.photos ? payload.photos.photo : null;
    let items = photos ? photos.map(photo => {
        let url = 'https://farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_b.jpg';
        return {
            id: photo.id,
            name: photo.title,
            owner: photo.ownername,
            image_url: url,
            source: url,
            scope: 'FLICKR',
            //image_url: ('//farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_c.jpg'),
            //thumb_url: ('//farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_z.jpg'),
            owner_url: ('https://www.flickr.com/photos/' + photo.owner)
        };
    }) : null;
    let media = { ...state };
    if (!media[id]) {
        media[id] = {}
    }
    media[id].flickrMedia = items;
    media[id].flickrMediaCount = payload.pages * payload.perpage;
    return {
        ...media
    }
};



const getPixabayMedia = (state, payload, id) => {
    let photos = payload ? payload.hits : null;

    let items = photos ? photos.map(photo => {
        return {
            id: photo.id,
            name: photo.tags ? photo.tags : '',
            owner: photo.user,
            image_url: photo.largeImageURL,
            source: photo.largeImageURL,
            scope: 'PIXABAY',
            //image_url: ('//farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_c.jpg'),
            //thumb_url: ('//farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_z.jpg'),
            owner_url: photo.userImageURL
        };
    }) : null;
    let media = { ...state };
    if (!media[id]) {
        media[id] = {}
    }
    media[id].pixabayMedia = items;
    media[id].pixabayCount = payload.totalHits;
    return {
        ...media
    }
};

const getWikiMedia = (state, payload, id) => {
    let pages = payload ? payload.query.pages : null;
    let items = pages?.map(page => {
        if (page.original && page.original.source) {
            return {
                id: page.pageid,
                name: page.title,
                owner: '',
                scope: 'WIKI',
                image_url: page.original.source,
                owner_url: '',
                source: page.original.source
            };
        } else {
            return null;
        }
    });
    let media = { ...state };
    if (!media[id]) {
        media[id] = {}
    }
    media[id].wikiMedia = items;
    media[id].wikiMediaCount = items.length;
    return {
        ...media
    }
};

const getGoogleMedia = (state, payload, id) => {

    const getWawPhotosFromPlace = (googlePlace) => {
        var pictures = [];
        var thumb_url, photo_url, photo_owner_name, photo_owner_url = null;
        for (var item in googlePlace.photos) {
            var photo = googlePlace.photos[item];
            thumb_url = photo.getUrl({ 'maxWidth': 500, 'maxHeight': 350 });
            photo_url = photo.getUrl({ 'maxWidth': 2000, 'maxHeight': 1200 });
            if (photo.html_attributions) {
                var template = document.createElement('template');
                template.innerHTML = photo.html_attributions;
                photo_owner_name = template.content.firstChild.innerText;
                photo_owner_url = template.content.firstChild.href;
            }

            var picture = {
                id: Date.now(),
                name: '',
                scope: 'GOOGLE',
                owner: photo_owner_name,
                image_url: photo_url,
                owner_url: photo_owner_url,
                source: photo_url
            };
            pictures.push(picture);
        }
        return pictures;
    }
    var items = [];
    for (var idx in payload) {
        var place = payload[idx];
        if (place.photos && place.photos.length > 0) {
            items.push(...getWawPhotosFromPlace(place))
        }
    }

    let media = { ...state };
    if (!media[id]) {
        media[id] = {}
    }
    media[id].googleMedia = items;
    media[id].googleMediaCount = items.length;
    return {
        ...media
    }
};


const addMedia = (state, payload, id) => {
    let media = { ...state };
    if (!media[id]) {
        media[id] = {}
    }

    if (media[id].media) {
        media[id].media.unshift(payload);
        media[id].mediaCount += 1
    } else {
        media[id].media = [payload]
        media[id].mediaCount = 1
    }
    media[id].loading = false;
    return media;
};

const deleteMedia = (state, payload, id, mediaId) => {
    let entity_media = state[id] ? state[id].media : null;
    let items = entity_media.filter(item => {
        return item.id !== mediaId
    })
    state[id].media = items;
    return {
        ...state
    };
};

const media = (state = initialState, action) => {
    const { type, payload, id, mediaId } = action;

    switch (type) {
        case MEDIA_GET_START:
            return loadingMedia(state, id, true);
        case MEDIA_GET_DONE:
            return loadingMedia(state, id, false);
        case MEDIA_RECEIVED:
            return getMedia(state, payload, id);
        case MEDIA_RECEIVED_LINKED_ENTITIES:
            return getMediaFromLinkedEnities(state, payload);
        case MEDIA_ADDED:
            return addMedia(state, payload, id);
        case MEDIA_DELETED:
            return deleteMedia(state, payload, id, mediaId);
        case MEDIA_GET_FLICKR:
            return getFlickrMedia(state, payload, id);
        case MEDIA_GET_WIKI:
            return getWikiMedia(state, payload, id);
        case MEDIA_GET_GOOGLE:
            return getGoogleMedia(state, payload, id);
        case MEDIA_GET_PIXABAY:
            return getPixabayMedia(state, payload, id);
        default:
            return state;
    }
};

export default media;