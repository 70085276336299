import { setAlert } from "./alert";
import { TRACKING_GET_ALL_BY_USER, TRACKING_GET_ALL_BY_ENTITY, ADD_TRACKED_FLIGHT, DELETE_TRACKED_FLIGHT } from "../types";
import { getErrorMsg, setAuthHeaders, setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";


export const getFlightsByUser = (username) => async dispatch => {
    try {

        const url = `/tracked_flights/profiles/${username}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson(),
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: TRACKING_GET_ALL_BY_USER, payload: responseData, username: username });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

export const addFlight = (username, flight) => async dispatch => {
    try {

        const url = `/tracked_flights/profiles/${username}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(flight)
        });
        const responseData = await response.json();

        if (response.status === 200) {
            dispatch({ type: ADD_TRACKED_FLIGHT, payload: responseData, username: username });
        } else {
            const msg = getErrorMsg(responseData)
            //dispatch(setAlert(msg, 'error', 5000));
            console.log(responseData)
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const deleteFlight = (username, entityId, flightId) => async dispatch => {
    try {
        const url = `/tracked_flights/profiles/${username}/${flightId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders(),
        });
        if (response.status === 204) {
            dispatch({ type: DELETE_TRACKED_FLIGHT, entityId: entityId, flightId: flightId, username: username });
        } else {
            const responseData = await response.json();
            const msg = getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};