import { setAlert } from "./alert";
import { ADS_GET } from "../types/ads";
import { setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";


export const getEntityAds = (id) => async dispatch => {
    try {
        const url = `/ads/entities/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson(),
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: ADS_GET, payload: responseData, entity_id: id });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

