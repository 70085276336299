//
// Scroll functions
//

import { useEffect, useState, useCallback } from "react"

// Display button based on scroll depth
export const useScrollDepth = ( displayButton ) => {
  // Toggle the display state
  const [ display, setDisplay ] = useState( displayButton ? false : true )

  // Define scroll depth required to display the button
  const offsetScroll = () => {
    // Check the scroll depth
    if ( window.pageYOffset > displayButton ) {
      if ( !display ) setDisplay( true )
    } else {
      if ( display ) setDisplay( false )
    }
  }

  useEffect(() => {
    if ( displayButton ) {
      //
      window.addEventListener( 'scroll', offsetScroll )
      return () => window.removeEventListener( 'scroll', offsetScroll )
    }
  })

  return [ display ]
}

// Displays the object based on scroll direction
export const useScrollDirection = () => {
  // Toggle the display state and set the current scroll depth
  const [ display, setDisplay ] = useState( true )
  const [ scrollDepth, setScrollDepth ] = useState( typeof window !== 'undefined' ? window.scrollY : '0' )

  // Define the scroll direction
  const handleScrollDepth = useCallback(
    event => {
      const window = event.currentTarget
      // Handle scroll up/down
      if ( scrollDepth > window.scrollY ) {
        // Displays the object if scrolling up
        setDisplay( true )
      } else if ( scrollDepth < window.scrollY ) {
        // Hides the object if scrolling down
        setDisplay( false )
      }
      setScrollDepth( window.scrollY )
    }, [ scrollDepth ]
  )

  useEffect(() => {
    setScrollDepth( window.scrollY )
    window.addEventListener( 'scroll', handleScrollDepth )

    return () => {
      window.removeEventListener( 'scroll', handleScrollDepth )
    }
  }, [ handleScrollDepth ])

  return [ display ]
}
