import { GET_PROFILE_FOLLOWERS, GET_PROFILE_FOLLOWING, PROFILE_GET, PROFILE_SET, PROFILE_SET_PICTURE } from "../types";

const initialState = {
};

const setProfile = (state, payload, username) => {
    state[username] = payload
    return {
        ...state
    }
};


const setProfileFollowers = (state, payload, username) => {
    if (!state.hasOwnProperty(username)) {
        state[username] = {}
        state[username]['followers'] = payload.profiles;
        state[username]['followers_count'] = payload.profilesCount;
        state[username]['followers_loading'] = false
    }
    return {
        ...state
    }
};

const setProfileFollowing = (state, payload, username) => {
    if (!state.hasOwnProperty(username)) {
        state[username] = {}
        state[username]['following'] = payload.profiles;
        state[username]['following_count'] = payload.profilesCount;
        state[username]['following_loading'] = false
    }
    return {
        ...state
    }
};

const getAllEntries = (state, payload, username) => {
    if (!state.hasOwnProperty(username)) {
        state[username] = {}
        state[username]['calentries'] = payload.calentries;
        state[username]['count'] = payload.calentriesCount;
        state[username]['loading'] = false
    }
    return {
        ...state
    }
};

const setProfilePicture = (state, payload, username) => {
    if (!state.hasOwnProperty(username)) {
        state[username] = {}
    }
    state[username]['image'] = payload.profile.image;
    return {
        ...state
    }
};

const profiles = (state = initialState, action) => {
    const { type, payload, username } = action;

    switch (type) {
        case PROFILE_SET:
            return setProfile(state, payload, username);
        case GET_PROFILE_FOLLOWERS:
            return setProfileFollowers(state, payload, username)
        case GET_PROFILE_FOLLOWING:
            return setProfileFollowing(state, payload, username)
        case PROFILE_SET_PICTURE:
            return setProfilePicture(state, payload, username);

        default:
            return state;
    }
};
export default profiles;

