import React, { useState, cloneElement } from "react";
import { makeStyles } from "@material-ui/core";
// Scroll hook
import { useScrollDirection } from "./hooks/useScroll";
import { AnonymousAppBar, AuthenticatedAppBar, Footer } from "./components";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.primary.contrastText,
    overflowX: "hidden",
    height: "100vh",
  },
  appnav: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
  },
  fadeIn: {
    transition: "all 300ms",
    transitionDelay: "0ms",
    animation: `$fadeFromTop 1s ease forwards`,
  },
  "@keyframes fadeFromTop": {
    "0%": {
      transform: "translateY( -250px )",
      opacity: 0,
    },
    "10%": {
      transform: "translateY( -60px )",
      opacity: 0,
      display: "block",
    },
    "100%": {
      transform: "translateY( 0px )",
      opacity: 1,
    },
  },
  fadeOut: {
    transition: "all 300ms",
    transitionDelay: "0ms",
    animation: `$fadeToTop 1s ease forwards`,
  },
  "@keyframes fadeToTop": {
    "0%": {
      transform: "translateY( 0px )",
      opacity: 1,
    },
    "90%": {
      transform: "translateY( -45px )",
      opacity: 0,
      display: "none",
    },
    "100%": {
      transform: "translateY( -400px )",
      display: "none",
      opacity: 0,
    },
  },
}));

function StructureLayout(props) {
  const classes = useStyles(props);
  const { children, withFooter, isAuthenticated, withHeader = true } = props;
  const [display] = useScrollDirection();
  const [root, setRoot] = useState(classes.scrollRoot);
  const header = isAuthenticated ? (
    <AuthenticatedAppBar {...props} />
  ) : (
    <AnonymousAppBar {...props} />
  );
  return (
    <div className={root}>
      {cloneElement(children, { isAuthenticated, setRoot })}
      <div className={classes.appnav}>
        {withHeader &&
          (display ? (
            <div className={classes.fadeIn}>{header}</div>
          ) : (
            <div className={classes.fadeOut}>{header}</div>
          ))}
      </div>
      {withFooter && <Footer />}
    </div>
  );
}

function mapStateToProps({ authState }) {
  return {
    user: authState.user,
    isAuthenticated: authState.isAuthenticated,
  };
}

const mapDispatchToProps = {};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StructureLayout))
);
