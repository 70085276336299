import { setAlert } from "./alert";
import { removeLoading, setLoading } from "./loading";
import {
    MEDIA_ADDED,
    MEDIA_DELETED,
    MEDIA_GET_FLICKR,
    MEDIA_GET_GOOGLE,
    MEDIA_GET_WIKI,
    MEDIA_NAME_SAVED,
    MEDIA_OWNER_NAME_SAVED,
    MEDIA_OWNER_URL_SAVED,
    MEDIA_RECEIVED,
    MEDIA_GET_PIXABAY
} from "../types/media";
import { setAuthHeaders, setAuthHeadersAsJson, WAW_API_ROOT } from '../../utils';

export const getEntityMedia = (entityId, proposal = true) => async dispatch => {
    try {
        const url = `/media/entities/${entityId}?proposal=proposal&scope=all`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_RECEIVED, payload: responseData, id: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

export const getFlickrMedia = (entityId, page, lat, lng, radius, limit = 100) => async dispatch => {
    dispatch(setLoading(5000))
    try {

        const url = 'https://api.flickr.com/services/rest/?method=flickr.photos.search&page='
            + page + '&per_page=' + limit + '&extras=owner_name'
            + '&api_key=' + process.env.REACT_APP_FLICKR_API_KEY + '&has_geo=1&lat=' + lat
            + '&lon=' + lng + '&radius=' + radius
            + '&format=json&nojsoncallback=1';
        const response = await fetch(`${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_GET_FLICKR, payload: responseData, id: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }

    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};



export const getPixabayMedia = (entityId, page, search) => async dispatch => {
    if (!search) {
        dispatch(setAlert("Enter search term for Pixabay", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))
    try {
        const url = "https://pixabay.com/api/?"
            + "q=" + search
            + '&key=' + process.env.REACT_APP_PIXABAY_API_KEY

        const response = await fetch(`${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        console.log(responseData)

        if (responseData) {
            dispatch({ type: MEDIA_GET_PIXABAY, payload: responseData, id: entityId });
        }

    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const getWikiMediaDetails = (entityId, title) => async dispatch => {
    try {
        var url = 'https://en.wikipedia.org/w/api.php?origin=*&action=query&format=json&formatversion=2&prop=pageimages|pageterms' +
            '&piprop=original&titles=' + title;
        const response = await fetch(`${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_GET_WIKI, payload: responseData, id: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};


export const getWikiMedia = (entityId, page, lat, lng, radius) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        var url = "https://en.wikipedia.org/w/api.php";

        var params = {
            action: "query",
            list: "geosearch",
            gscoord: lat + "|" + lng,
            gsradius: 1000,
            gsnamespace: '6',
            gsprimary: 'all',
            format: "json"
        };

        url = url + "?origin=*";
        Object.keys(params).forEach(function (key) { url += "&" + key + "=" + params[key]; });

        const response = await fetch(`${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            let photos = responseData && responseData.query ? responseData.query.geosearch : null;

            if (!photos || photos.length === 0) {
                dispatch(removeLoading(5000));
            } else {
                photos.map(photo => {
                    return dispatch(getWikiMediaDetails(entityId, photo.title))
                });
            }
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
            dispatch(removeLoading(5000))
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        dispatch(removeLoading(5000))
        console.log(error)
    }
}

let map = null;
let service = null;

export const getGoogleMedia = (entityId, lat, lng, radius, placeId, limit) => async dispatch => {
    //if (placeId) dispatch(getGoogleMediaByGooglePlaceId(entityId, placeId, limit));
    if (lat && lng) dispatch(getGoogleMediaByLocation(entityId, lat, lng, radius, limit));
};

export const getGoogleMediaByLocation = (entityId, lat, lng, radius, limit) => async dispatch => {
    try {

        if (!map) map = new window.google.maps.Map(document.createElement('div'));
        if (!service) service = new window.google.maps.places.PlacesService(map);
        var callback = function (places) {
            dispatch({ type: MEDIA_GET_GOOGLE, payload: places ? places : [], id: entityId });
        }
        var location = new window.google.maps.LatLng(lat, lng);
        var request = {
            location: location,
            radius: radius,
            rankby: 'distance'
        };
        service.nearbySearch(request, callback);

    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

export const getGoogleMediaByGooglePlaceId = (entityId, placeId, limit) => async dispatch => {
    try {
        console.log('getGoogleMediaByGooglePlaceId', entityId, placeId, limit)
        if (!map) map = new window.google.maps.Map(document.createElement('div'));
        if (!service) service = new window.google.maps.places.PlacesService(map);
        var callback = function (info, status) {
            console.log('response', placeId, info, status);
            //dispatch({type: MEDIA_GET_GOOGLE, payload: info ? info : [], id:entityId});
        }

        const request = {
            placeId: placeId,
            /*fields: ["name", "formatted_address", "place_id", "geometry", "photos"],*/
            fields: ["place_id"]
        };
        console.log('request', placeId);

        service.getDetails(request, callback);
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};


export const addMedia = (entityId, data) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/media/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeaders(),
            body: data
        });
        const responseData = await response.json();
        if (response.ok && responseData.media) {
            dispatch({ type: MEDIA_ADDED, payload: responseData.media, id: entityId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};
export const addMediaExternal = (entityId, data, isAvatar) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/media/entities/${entityId}/external`;

        var req = { ...data, url: data.image_url };
        delete req.image_url;
        delete req.thumb_url;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(req)
        });
        const responseData = await response.json();
        if (response.status === 201) {
            dispatch({ type: MEDIA_ADDED, payload: responseData.media, id: entityId });
            dispatch(setAlert("Saved Successfully", 'success', 5000));
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const deleteMedia = (entityId, mediaId) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/media/entities/${entityId}/${mediaId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders()
        });
        const responseData = await response
        if (response.status === 204) {
            dispatch({ type: MEDIA_DELETED, payload: responseData, id: entityId, mediaId: mediaId });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
    dispatch(removeLoading(5000))
};

export const mediaSaveName = (id, name) => async dispatch => {
    try {
        const url = `/media/update-partial/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ name, id: id })
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_NAME_SAVED, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};

export const mediaSaveOwnerName = (id, owner) => async dispatch => {
    try {
        const url = `/media/update-partial/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ owner, id: id })
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_OWNER_NAME_SAVED, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};


export const mediaSaveOwnerUrl = (id, owner_url) => async dispatch => {
    try {
        const url = `/media/update-partial/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'PATCH',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ owner_url, id: id })
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: MEDIA_OWNER_URL_SAVED, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
        console.log(error)
    }
};