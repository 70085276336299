import * as React from "react";
import { useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  createEntity,
  getUnreadNotifications,
  logout,
} from "../../../../store/actions";
import { connect } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import styles from "./styles";
import { Link, NavLink, Redirect } from "react-router-dom";
import Social from "../../../../components/Social";
import { UserDrawer } from "../../components";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Alert, Fade, Popper, Stack } from "@mui/material";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CheckIcon from "@mui/icons-material/Check";
import AccountBoxIcon from "@material-ui/icons/AccountBoxOutlined";

const types = ["success", "info", "warning", "error"];
const pages = [
  {
    name: "Inspirations",
    url: "/explore?type=inspiration",
  },
  {
    name: "Places",
    url: "/explore?type=place",
  },
  {
    name: "Events",
    url: "/explore?type=event",
  },
];

const AuthenticatedAppBar = (props) => {
  const {
    classes,
    user,
    logout,
    createEntity,
    createdEntity,
    notifications,
    getUnreadNotifications,
  } = props;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, toggleDrower] = React.useState(false);
  const [type, setType] = React.useState("inspiration");
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [name, setName] = React.useState(null);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [canCreate, setCanCreate] = React.useState(false);
  const [showUnreadOnly, setShowUnreadOnly] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);

  const toggleNotificationCenter = (event) => {
    setAnchor(event.currentTarget);
    setIsOpen(!isOpen);
  };
  const toggleFilter = (e) => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  // useInterval(() => {
  //     getUnreadNotifications();
  // }, 2000);

  /*useEffect(() => {
        if (!notifications) {
            getUnreadNotifications();
        }
    }, [notifications, getUnreadNotifications]);*/

  if (createdEntity != null && canCreate) {
    window.location.reload();
    return (
      <Redirect
        key={createdEntity.id}
        to={"/" + createdEntity.type + "/" + createdEntity.slug}
      />
    );
  }

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    window.location.reload();
  };

  const toggleDrawer = () => {
    toggleDrower(!drawerOpen);
  };

  const handleCreateOpen = () => {
    setCreateOpen(true);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const handleTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setType(typeof value === "string" ? value.split(",") : value);
  };

  const handleNameChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value && typeof value === "string" && value.length > 0) {
      setName(value);
      if (type) {
        setCanCreate(true);
        setCreateDisabled(false);
      }
    }
  };

  const handleCreate = () => {
    if (name && type && canCreate) {
      createEntity(name, type, user);
    }
  };
  const clearAll = () => {};
  const markUnreadAll = () => {};
  const markAsRead = (id) => {};

  /*const {
        notifications,
        clear,
        markAllAsRead,
        markAsRead,
        unreadCount
    } = useNotificationCenter();*/

  return (
    <AppBar position="static" className={classes.appbar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img
                src="/logo.svg"
                alt="Why And Where"
                className={classes.logoImg}
              />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link to={page.url}>{page.name}</Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Link to="/">
              <img
                src="/logo.svg"
                alt="Why And Where"
                className={classes.logoImgSm}
              />
            </Link>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, marginLeft: "20px" }}>
            <Social classes={classes} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#263238", display: "block" }}
              >
                <Link to={page.url}>{page.name}</Link>
              </Button>
            ))}
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" }, marginRight: "10px" }}
          >
            <Button
              key="dashboard"
              onClick={toggleDrawer}
              sx={{ my: 2, color: "#263238", display: "block" }}
            >
              Dashboard
            </Button>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" }, marginRight: "10px" }}
          >
            <Button
              key="create"
              onClick={handleCreateOpen}
              sx={{ my: 2, color: "#263238", display: "block" }}
            >
              Create
            </Button>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" }, marginRight: "10px" }}
          >
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={toggleNotificationCenter}
            >
              <Badge
                badgeContent={notifications ? notifications.length : null}
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {user && user.image ? (
                <Avatar
                  alt={user.first_name + " " + user.last_name}
                  src={user.image}
                />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
            <Menu
              sx={{ mt: "45px", minWidth: "80px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="profile" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">
                  <Link to={"/profile/@" + user.username}>Profile</Link>
                </Typography>
              </MenuItem>

              <MenuItem key="logout" onClick={logout}>
                <Typography>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <UserDrawer toggleDrawer={toggleDrawer} open={drawerOpen} />
      <Dialog open={createOpen} onClose={handleCreateClose}>
        <DialogTitle>Create New Content</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new content based on the type.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="entity_name"
            label="Name of your content"
            type="name"
            variant="standard"
            fullWidth
            onChange={handleNameChange}
          />
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="type-select"
              value={type}
              label="Type"
              onChange={handleTypeChange}
            >
              <MenuItem value="inspiration">
                Inspiration or Recommendation
              </MenuItem>
              <MenuItem value="deal">Event</MenuItem>
              <MenuItem value="place">Place</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateClose}>Cancel</Button>
          <Button onClick={handleCreate} disabled={createDisabled}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Popper open={isOpen} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={350}
            style={{ position: "fixed", top: "60px", right: 0 }}
          >
            <Box>
              <Box
                sx={{
                  background: "#fff",
                  padding: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></Box>
              <Stack
                sx={{
                  height: "400px",
                  width: "min(60ch, 100ch)",
                  padding: "12px",
                  background: "#fff",
                  borderRadius: "8px",
                  overflowY: "auto",
                }}
                spacing={2}
              >
                {(!notifications.length ||
                  (notifications && notifications.length === 0)) && (
                  <h4>
                    Your queue is empty! you are all set{" "}
                    <span role="img" aria-label="dunno what to put"></span>
                  </h4>
                )}
                {(showUnreadOnly
                  ? notifications.filter((v) => v.unread)
                  : notifications
                ).map((notification) => {
                  return (
                    <Alert
                      severity={notification.level || "info"}
                      action={
                        !notification.unread ? (
                          <CheckIcon />
                        ) : (
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => markAsRead(notification.id)}
                          >
                            <MarkChatReadIcon />
                          </IconButton>
                        )
                      }
                    >
                      {notification.description}
                    </Alert>
                  );
                })}
              </Stack>
              <Box
                sx={{
                  background: "#fff",
                  padding: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button variant="outlined" onClick={clearAll}>
                  Clear All
                </Button>

                <Button variant="outlined" onClick={markUnreadAll}>
                  Mark as read
                </Button>
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    </AppBar>
  );
};

function mapStateToProps({
  entityState,
  authState,
  notificationsState,
  props,
}) {
  return {
    isAuthenticated: authState.isAuthenticated,
    createdEntity: entityState.selectedEntity,
    notifications: notificationsState.unreadNotifications,
    user: authState.user,
  };
}

const mapDispatchToProps = {
  createEntity,
  getUnreadNotifications,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AuthenticatedAppBar));
