import { setAlert } from "./alert";
import { removeLoading, setLoading } from "./loading";
import {
    OPENAI_QUERY_SET,
    ENTITY_CREATED,
    ENTITY_UPDATED,
    MEDIA_RECEIVED_LINKED_ENTITIES,
    ENTITY_OPENAI_QUIERIES_RECEIVED,
    ENTITIES_GET_BY_QUERY
} from "../types";
import { setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";


export const generate = (prompt, intent, temperature, bounds, entityId, key) => async dispatch => {
    switch (intent) {
        case 'CREATE':
            dispatch(generateCREATE(prompt, temperature, bounds, key));
            return;
        case 'ASK':
            dispatch(generateASK(prompt, temperature, bounds, entityId, key));
            return;
        case 'FIND':
            dispatch(generateFIND(prompt, temperature, bounds, entityId, key));
    }
}

export const generateASK = (prompt, temperature, bounds, entityId, key) => async dispatch => {
    console.log('generateASK', bounds)
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))

    try {
        const url = `/ai/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "ASK", bounds: bounds, temperature: (temperature ? temperature : 0.3) })
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};


export const generateFIND = (prompt, temperature, bounds, entityId, key) => async dispatch => {
    console.log('generateFIND', bounds)
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))

    try {
        var boundaries = null;

        const url = `/ai/entities/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "FIND", bounds: bounds, temperature: (temperature ? temperature : 0.3) })
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });

        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};


export const generateCREATE = (prompt, temperature, bounds, key) => async dispatch => {
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }

    try {
        const url = `/ai/entities/create`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "CREATE", bounds: bounds, temperature: (temperature ? temperature : 0.3) })
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });
            dispatch({ type: ENTITY_CREATED, payload: responseData.entity, key: key });
            dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: { entities: [responseData.entity] } });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const getOpenAIQuery = (queryId, entityId) => async dispatch => {

    if (!queryId) {
        console.log('getOpenAIQuery query id cannot be null', queryId)
        return;
    }
    try {
        const url = `/ai/entities/${entityId}/queries/${queryId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query, id: entityId });
            if (responseData.entity) {
                dispatch({ type: ENTITY_UPDATED, payload: responseData.entity });
                dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: { entities: [responseData.entity] } });
                dispatch(getOpenAIQueryResults(responseData.query.id, entityId, queryId))
            }
        }
    } catch (error) {
        console.log(error)
    }

};

export const getOpenAIQueryResults = (queryId, entityId, key) => async dispatch => {
    if (!queryId) {
        console.log('getOpenAIQueryResults query id cannot be null', queryId)
        return;
    }
    try {
        const url = `/ai/entities/${entityId}/queries/${queryId}/result`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            const responseData = await response.json();
            if (responseData) {
                dispatch({ type: ENTITIES_GET_BY_QUERY, payload: responseData, key: key });
                dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: responseData });
            }
        }
    } catch (error) {
        console.log(error)
    }

};

export const getOpenAIQueries = (entityId) => async dispatch => {
    if (!entityId) {
        console.log('getOpenAIQueries entityId cannot be null', entityId)
        return;
    }
    try {
        const url = `/ai/entities/${entityId}/queries`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: ENTITY_OPENAI_QUIERIES_RECEIVED, payload: responseData, entityId: entityId });
        }
    } catch (error) {
        console.log(error)
    }

};