import { setAlert } from "./alert";
import {
  TAG_ADD,
  TAG_DELETE,
  TAG_GET,
  TAG_GET_ALL,
  TAG_GET_POPULAR,
  TAGS_SET_LOADING,
} from "../types";
import { setAuthHeadersAsJson, WAW_API_ROOT } from "../../utils";

export const getAllTags = () => async (dispatch) => {
  dispatch({ type: TAGS_SET_LOADING, payload: true });
  try {
    const url = "/tags/curated";
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: "GET",
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch({ type: TAG_GET_ALL, payload: responseData });
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error.message, "error", 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, "error", 5000));
    console.log(error);
  }
  dispatch({ type: TAGS_SET_LOADING, payload: false });
};

export const getEntityTags = (id) => async (dispatch) => {
  dispatch({ type: TAGS_SET_LOADING, payload: true });
  try {
    const url = `/tags/entities/${id}`;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: "GET",
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch({ type: TAG_GET, payload: responseData });
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error.message, "error", 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, "error", 5000));
    console.log(error);
  }
  dispatch({ type: TAGS_SET_LOADING, payload: false });
};

export const getPopularTags = () => async (dispatch) => {
  dispatch({ type: TAGS_SET_LOADING, payload: true });
  try {
    const url = "/tags/popular";
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: "GET",
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch({ type: TAG_GET_POPULAR, payload: responseData });
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error.message, "error", 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, "error", 5000));
    console.log(error);
  }
  dispatch({ type: TAGS_SET_LOADING, payload: false });
};

export const addTag = (id, tag) => async (dispatch) => {
  try {
    const url = `/tags/entities/${id}`;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: "POST",
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify({ tag }),
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch({ type: TAG_ADD, payload: responseData });
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error.message, "error", 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, "error", 5000));
    console.log(error);
  }
};

export const deleteTag = (id, tagId) => async (dispatch) => {
  try {
    const url = `/tags/entities/${id}/tags/${tagId}`;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: "DELETE",
      headers: setAuthHeadersAsJson(),
    });
    if (response.ok) {
      dispatch({ type: TAG_DELETE, payload: tagId });
    }
  } catch (error) {
    dispatch(setAlert(error.message, "error", 5000));
    console.log(error);
  }
};
