import {
  AIRPORT_ENTITY_ADDED,
  AIRPORT_ENTITY_DELETED,
  AIRPORT_ENTITY_GET_ALL,
  AIRPORT_GET_TO,
  AIRPORT_GET_FROM,
  AIRPORT_PROFILE_ADDED,
  AIRPORT_PROFILE_DELETED,
  AIRPORT_PROFILE_GET_ALL
} from '../types';

const initialState = {
  toAirports: null,
  fromFirports: null,
  profiles: {},
  entities: {}

};
const setProfileAirports = (state, payload, username) => {

  var profileState = state.profiles;
  if (!profileState.hasOwnProperty(username)) {
    profileState[username] = {};
  }
  profileState[username]['airports'] = payload.airports;
  profileState[username]['count'] = payload.airportsCount;
  profileState[username]['loading'] = false;
  return {
    ...state
  }
};


const deleteProfileAirport = (state, username, airportCode) => {

  var profileState = state.profiles;
  if (!profileState.hasOwnProperty(username)) {
    return {
      ...state
    };
  }
  let items = profileState[username]['airports'].filter(item => {
    return item.code !== airportCode
  })
  profileState[username]['airports'] = items ? items : [];
  profileState[username]['count'] = (profileState[username]['count'] > 0 ? profileState[username]['count'] - 1 : 0)
  profileState[username]['loading'] = false;

  return {
    ...state
  };

};

const setEntityAirports = (state, payload, entityId) => {
  var entitiesState = state.entities;
  if (!entitiesState.hasOwnProperty(entityId)) {
    entitiesState[entityId] = {};
    entitiesState[entityId]['airports'] = payload.airports;
    entitiesState[entityId]['count'] = payload.airportsCount;
    entitiesState[entityId]['loading'] = false;
  }
  return {
    ...state
  }
};

const deleteEntityAirport = (state, entityId, airportCode) => {
  var entitiesState = state.entities;
  if (!entitiesState.hasOwnProperty(entityId)) return;
  let items = entitiesState[entityId]['airports'].filter(item => {
    return item.id !== airportCode
  })
  entitiesState[entityId]['airports'] = items ? items : [];
  entitiesState[entityId]['count'] = (entitiesState[entityId]['count'] > 0 ? entitiesState[entityId]['count'] - 1 : 0)
  entitiesState[entityId]['loading'] = false;
  return {
    ...state
  };
};

const getFromAirports = (state, payload) => {
  return {
    ...state,
    fromAirports: payload,
    loading: false
  };
};

const getToAirports = (state, payload) => {

  return {
    ...state,
    toAirports: payload,
    loading: false
  };
};


const airport = (state = initialState, action) => {
  const { type, payload, direction, username, entityId, airportCode, } = action;

  switch (type) {
    case AIRPORT_PROFILE_GET_ALL:
      return setProfileAirports(state, payload, username);
    case AIRPORT_PROFILE_DELETED:
      return deleteProfileAirport(state, username, airportCode);
    case AIRPORT_ENTITY_GET_ALL:
      return setEntityAirports(state, payload);
    case AIRPORT_ENTITY_DELETED:
      return deleteEntityAirport(state, payload);
    case AIRPORT_GET_TO:
      return getToAirports(state, payload);
    case AIRPORT_GET_FROM:
      return getFromAirports(state, payload);
    default:
      return state;
  }
};
export default airport;
