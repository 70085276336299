import { TRACKING_GET_ALL_BY_USER, TRACKING_GET_ALL_BY_ENTITY, ADD_TRACKED_FLIGHT, DELETE_TRACKED_FLIGHT } from '../types';

const initialState = {
};


const getTrackedFlightsByUser = (state, payload, username) => {
  if (!state.hasOwnProperty(username)) {
    state[username] = {}
    state[username]['flights'] = payload.flights;
    state[username]['count'] = payload.flightsCount;
    state[username]['loading'] = false
  }
  return {
    ...state
  }
};
const addTrackedFlight = (state, payload, username) => {
  console.log('payload.flights', payload.flights)
  if (state.hasOwnProperty(username)) {
    state[username]['flights'].push(payload.flights);
    state[username]['count'] = payload.flightsCount;

  } else {
    state[username] = {}
    state[username]['flights'] = [payload.flights]
    state[username]['count'] = payload.flightsCount;
  }
  state[username]['loading'] = false;
  console.log('reducer state', state)
  return {
    ...state
  };
};

const deleteTrackedFlight = (state, username, flightId) => {
  if (!state.hasOwnProperty(username)) return

  let items = state[username]['flights'].filter(item => {
    return item.id !== flightId
  })
  state[username]['flights'] = items ? items : [];
  state[username]['count'] = (state[username]['count'] > 0 ? state[username]['count'] - 1 : 0)
  state[username]['loading'] = false;
  return {
    ...state
  };
};

const flights = (state = initialState, action) => {
  const { type, payload, username, flightId } = action;

  switch (type) {
    case TRACKING_GET_ALL_BY_USER:
      return getTrackedFlightsByUser(state, payload, username);
    case ADD_TRACKED_FLIGHT:
      return addTrackedFlight(state, payload, username);
    case DELETE_TRACKED_FLIGHT:
      return deleteTrackedFlight(state, username, flightId);
    default:
      return state;
  }
};
export default flights;
