import {
    OPENAI_QUERY_SET,
    ENTITY_OPENAI_QUIERIES_RECEIVED
} from '../types';

const initialState = {
    entities: {}
};

const setQueries = (state, payload) => {

    if (!payload) return state;
    for (var id in payload) {
        var query = payload[id];
        state[query.id] = query;
        var id = query.init_entity;
        if (!state['entities'].hasOwnProperty(id)) {
            state['entities'][id] = [];
        }
        if (!state['entities'][id].includes(query.id)) {
            state['entities'][id].push(query.id);
        }
    }
    return {
        ...state
    }

};


const setQuery = (state, query) => {
    if (!query) return state;
    state[query.id] = query;
    var id = query.init_entity;

    if (!state['entities'].hasOwnProperty(id)) {
        state['entities'][id] = [];
    }
    if (!state['entities'][id].includes(query.id)) {
        state['entities'][id] = [query.id, ...state['entities'][id]]
    }
    return {
        ...state
    }
};


const loading = function (state = initialState, action) {
    const { type, query, payload } = action;
    switch (type) {
        case OPENAI_QUERY_SET:
            return setQuery(state, query)
        case ENTITY_OPENAI_QUIERIES_RECEIVED:
            return setQueries(state, payload)
        default:
            return state;
    }
}

export default loading;
