import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Sidebar } from "./Sidebar";

const styles = (theme) => ({
  drawer: {
    width: 250,
    zIndex: 1300,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },

  mapdrawer: {
    width: "100vw",
    zIndex: 1300,
  },

  smallDrawer: {
    width: 250,
  },

  navDrawer: {
    width: 80,
  },

  fullList: {
    width: "auto",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px !important",
  },
  content: {
    display: "flex",
    fontSize: "1.5em",
  },
  farRightToggleButton: {
    position: "absolute",
    right: 2,
  },
  drawerPaper: {
    borderRight: 0,
    zIndex: 1200,
    width: "271px",
    top: theme.topBar.height,
  },
  sidebar: {
    width: "100%",
  },
});

class UserDrawer extends React.Component {
  render() {
    const {
      children,
      classes,
      width,
      isNav,
      variant,
      position,
      title,
      username,
      open,
      isComments,
      isMap,
      toggleDrawer,
    } = this.props;
    return (
      <div>
        <Drawer
          anchor={position}
          open={open}
          onClose={toggleDrawer}
          variant={variant}
          classes={{
            paper: width
              ? isNav
                ? classes.navDrawer
                : classes.smallDrawer
              : isMap
              ? classes.mapdrawer
              : classes.drawer,
          }}
        >
          <div className={classes.toolbar}>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                this.props.toggleDrawer("persistent");
              }}
              className={classes.farRightToggleButton}
            >
              <Close />
            </IconButton>
          </div>
          <Divider />
          <div
            tabIndex={0}
            role="button"
            onClick={!isComments ? this.props.toggleDrawer : undefined}
            className={classes.content}
            onKeyDown={!isComments ? this.props.toggleDrawer : undefined}
          >
            {children ? (
              children
            ) : (
              <Sidebar className={classes.sidebar} username={username} />
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

UserDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDrawer);
