import {
  ADD_USER,
  DELETE_USER,
  GET_USERS,
  SELECT_USER,
  TOGGLE_USER_DIALOG,
  UPDATE_USER
} from '../types';

import { setAlert } from './alert';
import { setAuthHeaders, setAuthHeadersAsJson } from "../../utils";

const WAW_API_ROOT = '/api';

export const toggleUserDialog = () => ({ type: TOGGLE_USER_DIALOG });


export const selectUser = user => ({
  type: SELECT_USER,
  payload: user
});

export const subcribeForNewsletter = (email) => async dispatch => {
  try {

    const url = '/public/subscribe';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeaders(),
      body: JSON.stringify({ 'email': email })
    });
    const users = await response.json();
    if (response.ok) {
      dispatch(setAlert(response.message, 'success', 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
    console.log(error)
  }
};

export const getUsers = () => async dispatch => {
  try {

    const url = '/users';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const users = await response.json();
    if (response.ok) {
      dispatch({ type: GET_USERS, payload: users });
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
    console.log(error)
  }
};

export const addUser = user => async dispatch => {
  try {
    const url = '/users/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify(user)
    });
    const data = await response.json();
    const newUser = data.user;
    if (response.ok) {
      dispatch(setAlert('User Created', 'success', 5000));
      dispatch({ type: ADD_USER, payload: newUser });
      return { status: 'success', message: 'User Created' };
    } else {
      throw new Error(data._message);
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
    console.log(error)
    return {
      status: 'error',
      message: ' User have not been saved, try again.'
    };
  }
};

export const updateUser = (id, user) => async dispatch => {
  try {
    const url = '/users/' + id;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'PATCH',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify(user)
    });
    const data = await response.json();
    const newUser = data.user;
    if (response.ok) {
      dispatch(setAlert('User Updated', 'success', 5000));
      dispatch({ type: UPDATE_USER, payload: newUser });
      return { status: 'success', message: 'User Updated' };
    } else {
      throw new Error(data._message);
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
    console.log(error)
    return {
      status: 'error',
      message: ' User have not been saved, try again.'
    };
  }
};

export const deleteUser = id => async dispatch => {
  try {
    const url = '/users/' + id;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'DELETE',
      headers: setAuthHeadersAsJson()
    });
    const data = await response.json();
    if (response.ok) {
      dispatch(setAlert('User Deleted', 'success', 5000));
      dispatch({ type: DELETE_USER, payload: id });
      return { status: 'success', message: 'User Removed' };
    } else {
      throw new Error(data._message);
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
    console.log(error)
    return {
      status: 'error',
      message: ' User have not been deleted, try again.'
    };
  }
};
