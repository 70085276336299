import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { setAuthHeadersAsJson } from "../utils";

const config = {
    currentAccessToken: () => {
        var accessToken = localStorage.getItem('jwt_access_token')
        if (!accessToken) return null;
        if (accessToken === 'undefined') {
            localStorage.removeItem('user');
            localStorage.removeItem('jwt_access_token');
            return null;
        }
        const decoded = jwtDecode(accessToken);
        if (decoded.exp && decoded.exp - moment().unix() < 20) {
            return null;
        }
        return accessToken;
    },
    currentRefreshToken: () => {
        var refreshToken = localStorage.getItem('jwt_refresh_token');
        if (!refreshToken) return null;
        if (refreshToken === 'undefined') {
            localStorage.removeItem('jwt_refresh_token');
            return null;
        }
        return refreshToken;
    },
    handleRefreshAccessToken: (refreshToken, authState) => {
        return new Promise(async (resolve, reject) => {
            const url = '/auth/dj-rest-auth/token/refresh/';
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: setAuthHeadersAsJson(),
                body: JSON.stringify({ 'token': refreshToken })
            });
            const json = await response.json();
            if (json.success === false) {
                reject(Error("Could not refresh authentication token."))
            } else {
                const { access, refresh } = json;
                authState.isAuthenticated = true;
                localStorage.setItem('jwt_access_token', access);
                localStorage.setItem('jwt_refresh_token', refresh);
                authState.tokenPromise = null;
                resolve(access, refresh)
            }
        }).catch(e => console.log(e));
    },
    handleAuthenticationError: (error, store) => {
        console.log("There was an error we should handle it!")
    }
}

const jwtRefreshMiddleware = ({ dispatch, getState }) => {
    return next => action => {
        if (typeof action === 'function') {
            var authState = getState().authState;
            const accessToken = config.currentAccessToken();
            const refreshToken = config.currentRefreshToken();

            if (refreshToken && (!accessToken || accessToken === 'undefined')) {
                if (!authState.tokenPromise) {
                    authState.tokenPromise = config.handleRefreshAccessToken(refreshToken, authState)
                        .then(() => {
                            return next(action);
                        });
                } else {
                    authState.tokenPromise.then(() => {
                        return next(action)
                    });
                }
            }
            if (authState.tokenPromise) {
                return authState.tokenPromise;
            }
        }
        return next(action);
    }
}

export default jwtRefreshMiddleware;